/* MessageAdmin.css */
.message-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
  }
  
  .message-list {
    list-style-type: none;
    padding: 0;
  }
  
  .message-item {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .message-item strong {
    color: #333;
  }
/* MessageAdmin.css */
.message-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
  }
  
  .message-list {
    list-style-type: none;
    padding: 0;
  }
  
  .message-item {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .message-item strong {
    color: #333;
  }

  /* MessageAdmin.css */
.message-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.message-list {
  list-style-type: none;
  padding: 0;
}

.message-item {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.message-item strong {
  color: #333;
}

.delete-button {
  background-color: #ff6347; /* Coral color */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
}

.delete-button:hover {
  background-color: #ff4838; /* Darker coral color on hover */
}

    